/** @format */

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Padyakke+Expanded+One&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

////////////////////// Body ////////////////////////

body {
  // color: #fff;
  // font-family: SourceCodePro;
  // font-weight: 300;
  // font-size: 1.125rem;
  // line-height: 1.56;
  background-color: #212529 !important;
  //  background-color: red !important;
}
::selection {
  background: #a9ff03;
  color: #212529;
}
::-webkit-scrollbar {
  width: 6px !important ;
}
::-webkit-scrollbar-thumb {
  background: #a9ff03;
}
::-webkit-scrollbar-track {
  background: #212529;
}
::sco h1,
h2,
h3,
h4,
h4 {
  // font-family: 'DIN Condensed' !important;
}
.color-5cffe4 {
  color: #5cffe4;
}
.color-a9ff03 {
  color: #a9ff03;
}
h2 {
  max-width: 45rem !important;
}
.custom-btn {
  display: inline-block;
  padding: 1.25rem 2.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  font-family: "Source Code Pro";
  color: #000;
  background: url(../public/assets/custom-btn.svg) 0 0/100% 100% no-repeat;
  &:hover {
    background: url(../public//assets/custom-btn-hover.svg) 0 0/100% 100%
      no-repeat;
    color: #000;
  }
}

/////////////////////////////////// TOOGLER ICON COLOR CHANGE COLOR //////////////////////////

.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #ffffff;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}
.navbar-toggler {
  background-color: #a9ff03 !important;
  padding: 10px !important;
}
.navbar-brand {
  font-size: 2rem !important;
  font-weight: 700;
  // letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}

/////////////////////////// HEADER SECTION ////////////////////////////////////////

.header-section {
  ul li a {
    font-family: "Source Code Pro";
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.56;
    &:hover {
      color: #a9ff03 !important;
    }
  }
  .btn {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.56;
    // font-family: 'Source Code Pro';
    border-radius: 0;
    font-weight: 400;
    margin: 10px;
  }
  .btn-apply {
    border: 1px solid #c58fff;
    color: #c58fff;
    &:hover {
      border-color: #d8b4ff;
      color: #d8b4ff;
    }
  }
  .btn-talent {
    color: #000;
    background: #a9ff03;
    &:hover {
      color: #000;
      background: #dfff00;
    }
  }
  .btn-login {
    padding: 0px;
    font-family: "Source Code Pro";
    &:hover span {
      display: none;
    }
    &:hover:before {
      content: "login /";
      color: #dfff00;
    }
  }
}

//////////////////////////// HERO SECTION ///////////////////////////////////

.Hero-section {
  .content-sec h1 {
    margin-bottom: 0.3125rem;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.07;
    letter-spacing: 0.4rem;
  }

  .content-desc {
    max-width: 34rem;
    width: 100%;
    font-size: 1rem;
    line-height: 1.63;
    margin-bottom: 1.875rem;
    font-family: "Source Code Pro";
  }

  .content-para {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.3125rem;
    margin-bottom: 2.1875rem;
    font-weight: bold;
  }
}

///////////////////////////// Miraculous-section  /////////////////////////////

.Miraculous-section {
  h4 {
    color: #ffffff;
    font-family: DINCondensed, -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto;
    font-size: 1.2rem;
    font-weight: 800;
  }
}

//////////////////////////////////// SatrtUp  ///////////////////////

.Startup-section {
  .row {
    img {
      margin-top: 10px;
    }
  }
  h2,
  h4 {
    font-size: 4rem;
    margin-bottom: 3rem;
    font-weight: 700;
    margin-right: 15px;
  }
  img {
    margin-top: -123px;
  }

  .text-size {
    max-width: 13.125rem;
    margin-left: 20px;
  }

  p {
    font-family: "Source Code Pro";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.56;
  }
}

//////////////////////////// Testamonial Section //////////////////

.Testemonial-section {
  position: relative;
  p {
    font-family: "Source Code Pro";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.56;
    line-height: 1.6;
  }
  .testemonial-sec {
    font-size: 1rem;
    width: 100%;
    height: 12.5rem;
    margin-top: -2.5rem;
    display: flex;
    align-items: center;
    padding: 2.125rem 1.25rem 5.125rem 5.875rem;
    background-image: url("../public/assets/testemonial.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content-sec {
    width: 26.875rem;
    .rotate-text {
      position: absolute;
      left: 0;
      bottom: -2.25rem;
      margin-left: 16.8125rem;
      transform: rotate(-2deg);
    }
  }
}

//////////////////////////////////// RESUME SECTION ////////////////

.Resume-section {
  .resume-sec-part {
    margin-left: 20px;
  }
  p {
    font-family: "Source Code Pro";
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.56;
    line-height: 1.6;
  }
  .Resume-type-sec {
    flex-wrap: wrap;
    h3 {
      // font-family: DINCondensed;
      font-weight: 700;
      font-size: 2.5rem;
    }

    .review {
      z-index: 5;
      width: 18.4375rem;
      height: 24.4375rem;
      background-image: url(../public/assets/level-1-number.png),
        url(../public/assets/level-1-bg.png);
      background-position: 0.9375rem 96%, 0 0;
      background-repeat: no-repeat, no-repeat;
      position: relative;
      margin-top: auto;
      margin-right: 0;
      padding: 1.5625rem 2.125rem;
      background-size: 4.6875rem 6.25rem, contain;
      margin-right: -0.75rem;
    }

    .personal {
      z-index: 4;
      width: 17.75rem;
      height: 29.3125rem;
      background-image: url(../public/assets/level-2-number.png),
        url(../public//assets/level-2-bg.png);
      background-position: 0.9375rem 97%, 0 0;
      background-repeat: no-repeat, no-repeat;
      background-size: 5.625rem 6.25rem, contain;
      position: relative;
      margin-top: auto;
      margin-right: 0;
      padding: 1.5625rem 2.125rem;
      margin-right: -0.75rem;
    }
    .hard-skill {
      z-index: 3;
      width: 16.5rem;
      height: 34.3125rem;
      background-image: url(../public/assets/level-3-number.png),
        url(../public/assets/level-3-bg.png);
      background-position: 0.9375rem 97%, 0 0;
      background-repeat: no-repeat, no-repeat;
      background-size: 5.625rem 6.25rem, contain;
      position: relative;
      margin-top: auto;
      margin-right: 0;
      padding: 1.5625rem 2.125rem;
      margin-right: -0.75rem;
    }

    .advocate {
      z-index: 2;
      width: 15.25rem;
      height: 39.375rem;
      background-image: url(../public//assets/level-4-number.png),
        url(../public/assets/level-4-bg.png);
      background-position: 0.9375rem 97.5%, 0 0;
      background-repeat: no-repeat, no-repeat;
      background-size: 5.9375rem 6.25rem, contain;
      position: relative;
      margin-top: auto;
      margin-right: 0;
      padding: 1.5625rem 2.125rem;
      margin-right: -0.75rem;
    }
  }
}

//////////////////// Utopia Section ////////////////////////////

@keyframes pigeon-left {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 0 50%;
  }

  0% {
    transform: rotate(2deg);
  }

  80% {
    transform: rotate(-4deg);
  }
}

@keyframes bearhand {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 100% 50%;
  }

  10% {
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

@keyframes myAnim {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}

.Utopia-section {
  h2 {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1.25rem;
    font-size: 3.5rem;
    line-height: 1.19;
    letter-spacing: 0.23875rem;
    color: #ffffff;
  }
  .left-hand {
    bottom: -5vw;
    top: 10rem;
    left: 1.4375rem;
    width: 22.5rem;
    z-index: 2;

    &:hover {
      animation: pigeon-left 0.5s linear 0s 1 normal forwards;
    }
  }
  .right-hand {
    top: 11.0625rem;
    left: 13.1875rem;
    width: 16.9375rem;
  }
  .skill {
    top: -0.8125rem;
    left: 6.8125rem;
    width: 15.9375rem;
    right: -15vw;
  }

  .beard {
    width: 18.25rem;
  }
  .beard-hand {
    top: 7.0625rem;
    left: -7.875rem;
    z-index: 1;
    width: 10.875rem;

    &:hover {
      animation: bearhand 1s linear 0s 1 normal forwards;
    }
  }

  .communication {
    top: 2.5rem;
    left: 9.875rem;
    z-index: 2;
    width: 14.875rem;
  }

  .dog-pic-combination {
    display: flex;
    justify-content: center;
    .dog-pic {
      width: 18.25rem;
    }
    .dog-hand-left {
      top: 18.0625rem;
      left: 20.125rem;
      z-index: 1;
      width: 13.875rem;
    }
    .dog-hand-right {
      top: 17.0625rem;
      left: 3.125rem;
      z-index: 1;
      width: 13.875rem;
    }
    .dog-hand-right,
    .dog-hand-left {
      &:hover {
        animation: myAnim 2s ease 0s 1 normal forwards;
      }
    }
    .communication {
      top: 6.5rem;
      left: 0.875rem;
      z-index: 2;
      width: 14.875rem;
    }
  }
  .rex-pic-combination {
    display: flex;
    justify-content: center;
    .rex-pic {
      width: 25.25rem;
    }
    .rex-hand-left {
      top: 12.0625rem;
      left: 15.125rem;
      z-index: 1;
      width: 10.875rem;
    }
    .rex-hand-right {
      top: 11.0625rem;
      left: 3.875rem;
      width: 10.875rem;
      z-index: 0;
    }
    .communication {
      top: 4.5rem;
      left: 26.875rem;
      z-index: 2;
      width: 14.875rem;
    }
    .rex-hand-right,
    .rex-hand-left {
      &:hover {
        animation: myAnim 2s ease 0s 1 normal forwards;
      }
    }
  }
}

/////////////////////////////// TEST Monial Section 2 ////////////////////

.Testemonial2-section {
  .testemonial-size {
    width: 44.125rem;
  }
  position: relative;

  .testemonial2-sec {
    font-size: 1rem;
    width: 35.6875rem;
    height: 18.375rem;
    padding: 2.875rem 4.3125rem 5.6875rem 3.25rem;
    margin-top: -2.5rem;
    display: flex;
    align-items: center;
    background-image: url("../public/assets/testemonial-2.svg");
    background-repeat: no-repeat;
    background-size: 50% contain;
  }
  .testemonial-icon {
    margin-top: -5rem;
  }

  .content-sec {
    width: 26.875rem;
    p {
      font-family: "Source Code Pro";
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.56;
      line-height: 1.6;
    }
    .rotate-text {
      position: absolute;
      left: -64px;
      bottom: 3.75rem;
      margin-left: 16.8125rem;
      transform: rotate(-2deg);
    }
  }
}

//////////////////////////////// Tower Section //////////////////////

.Tower-section {
  .tower-heading {
    width: 100%;
    margin-bottom: 1.875rem;
    text-transform: uppercase;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: 0.23875rem;

    span {
      color: #a9ff03;
    }
  }
  .tower-image-sec {
    position: relative;
    margin-top: 0;
    width: 100%;
    height: 55vw;
  }
  .tower-img {
    position: absolute;
    width: 80%;
    top: 10vw;
    right: 0;
  }

  .marque-div {
    position: absolute;
    width: 106.25rem;
    height: 4.375rem;
    right: 18.875rem;
    display: flex;
    padding: 0.3125rem 2.5rem;
    border: 3px solid #000;
    transform: rotate(-11deg);
    overflow: hidden;
    background: #a9ff03;
    box-sizing: border-box;
    border-bottom-right-radius: 32px;
    bottom: -27px;

    ul {
      display: flex;
      align-items: center;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ticker;
      animation-duration: 120s;
      list-style-position: inside;
      list-style: none;
      li {
        font-size: 1.75rem;
        font-weight: 500;
        color: #000;
        margin: 0 1.25rem;
        white-space: nowrap;
      }
    }
  }
}

////////////////////////// Remote Developer Section /////////////////////////
.Remote-developer-section {
  color: #ffffff;

  p {
    font-family: "Source Code Pro";
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.56;
    line-height: 1.6;
  }
  h2 {
    width: 100%;
    max-width: 47.5rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
    font-size: 3.5rem;
    line-height: 1.19;
    letter-spacing: 0.23875rem;
  }
  .desc-sec {
    width: 100%;
    max-width: 31rem;
    p {
      font-family: "Source Code Pro";
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.56;
      line-height: 1.6;
    }
  }

  .video-section1 {
    cursor: pointer;
    .play-btn {
      left: 3rem;
      bottom: 1.25rem;
      z-index: 5;
      width: 3.75rem;
      height: 4.6875rem;
    }
    .youtube-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

/////////////////////////// Testemonoial3 section ////////////////////

.Testemonial3-section {
  position: relative;
  p {
    font-family: "Source Code Pro";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.56;
    line-height: 1.6;
  }
  .testemonial1-content-sec {
    width: 26.875rem;
    .rotate-text {
      position: absolute;
      left: 0px;
      bottom: 8.75rem;
      margin-left: 7.8125rem;
      transform: rotate(-2deg);
      color: #ffffff;
    }
    .testemonial-sec {
      font-size: 1rem;
      width: 34.0625rem;
      height: 15.3125rem;
      margin-top: -2.5rem;
      display: flex;
      align-items: center;
      padding: 2.75rem 2.4375rem 4.4375rem 3.5rem;
      background-image: url("../public/assets/testemonial3.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: #ffffff;
    }
  }
  .testemonial1-icon {
    margin-left: 400px;
  }

  .testemonial2-content-sec {
    width: 26.875rem;
    position: relative;
    .rotate-text {
      position: absolute;
      left: 0px;
      bottom: 0.75rem;
      margin-left: 12.8125rem;
      transform: rotate(-2deg);
      color: #ffffff;
    }
    .testemonial-sec {
      font-size: 1rem;
      width: 25rem;
      height: 16.875rem;
      margin-top: -2.5rem;
      display: flex;
      align-items: center;
      padding: 2.9375rem 0.0625rem 7.375rem 2.8125rem;
      background-image: url("../public/assets/testemonial3-2.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: #ffffff;
    }
  }
}

////////////////////////////////// Sprint section //////////////

.Sprint-section {
  h2 {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1.25rem;
    font-size: 3.5rem;
    line-height: 1.19;
    letter-spacing: 0.23875rem;
    color: #ffffff;
  }
  p {
    font-family: "Source Code Pro";
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.56;
    line-height: 1.6;
  }
  h3 {
    margin-bottom: 0.9375rem;
    font-size: 2.7rem;
    line-height: 1;
    letter-spacing: 0.088rem;
    color: #000;
    font-weight: 700;
  }
  .card-1-report {
    .weeekly-report {
      background-repeat: no-repeat;
      background-image: url(../public//assets/card1-weekly-reports.webp),
        url(../public/assets/card-1-reports-bg.png);
      background-size: 12.75rem 16.875rem, cover;
      background-position: 85% bottom, 0 0;
      padding: 2.5rem 3.25rem 24.5rem 2.5rem;
      color: #000;
    }
  }
  .card-2-report {
    .weeekly-support {
      background-image: url(../public//assets/card2-hand-support.webp),
        url(../public/assets/card-1-reports-bg.png);
      padding: 22rem 3.25rem 24.5rem 2.5rem;
      color: #000;
      display: flex;
      flex-direction: column;
      background-repeat: no-repeat;
      background-size: 12.75rem 16.875rem, cover;
      background-position: 25% top, 0 0;
    }
  }
}

/////////////////////////// Testemonoial4 section ////////////////////

.Testemonial4-section {
  position: relative;
  p {
    font-family: "Source Code Pro";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.56;
    line-height: 1.6;
  }
  .testemonial1-content-sec {
    width: 26.875rem;
    .rotate-text {
      position: absolute;
      bottom: 10.75rem;
      margin-left: 7.8125rem;
      color: #ffffff;
      left: 3.4375rem;
      // bottom: 0;
      transform: rotate(5deg);
    }
    .testemonial-sec {
      font-size: 1rem;
      width: 34.0625rem;
      height: 15.3125rem;
      margin-top: -2.5rem;
      display: flex;
      align-items: center;
      padding: 2.75rem 2.4375rem 4.4375rem 3.5rem;
      background-image: url("../public/assets/testemonial3.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: #ffffff;
    }
  }
  .testemonial1-icon {
    margin-left: 400px;
  }

  .testemonial2-content-sec {
    width: 26.875rem;
    position: relative;
    .rotate-text {
      position: absolute;
      // left: 0px;
      bottom: 0.75rem;
      margin-left: 12.8125rem;
      bottom: 1.25rem;
      transform: rotate(3deg);
      color: #ffffff;
    }
    .testemonial-sec {
      font-size: 1rem;
      width: 25rem;
      height: 16.875rem;
      margin-top: -2.5rem;
      display: flex;
      align-items: center;
      padding: 2.9375rem 0.0625rem 7.375rem 2.8125rem;
      background-image: url("../public/assets/testemonial3-2.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: #ffffff;
    }
  }
}

////////////////////////////////////////////////////// Bugdet Section  ///////////////

.Budget-section {
  h2 {
    max-width: 54.375rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
    font-size: 2.5rem;
    line-height: 1.29;
    font-weight: 900;
    letter-spacing: 0.23875rem;
    color: #ffffff;
  }
  p {
    font-family: "Source Code Pro";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.56;
    line-height: 1.6;
    color: #ffffff;
  }
  h3 {
    margin-bottom: 0.625rem;
    font-size: 2.9rem;
    line-height: 0.94;
    letter-spacing: 0.1875rem;
    text-transform: uppercase;
    color: #000;
    font-weight: 900;
  }

  h5 {
    letter-spacing: 0.05rem;
    margin-bottom: -0.75rem;
    font-size: 2rem;
    font-weight: 700;
  }
  ul li {
    margin-bottom: 0.9375rem;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 500;
    font-family: "Source Code Pro";
  }
  .card1-bg {
    background-image: url(../public/assets/card-1-startup-plan-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .pegion-icon {
      bottom: 20px;
      right: 20px;
    }
  }
  .card2-bg {
    background-image: url(../public/assets/card-2-plus-cto-plan-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .pegion-icon {
      bottom: 20px;
      right: 20px;
    }
    .text-bg {
      background-image: url(../public/assets/text-bg-card.svg);
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      padding: 2.5rem 1.5625rem;
      bottom: -40px;
    }
    .text-rotate {
      transform: rotate(-11deg) translateY(-0.125rem);
      color: #000;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

////////////// Faqs section //////////////

.Faqs-section {
  .row-title-text,
  p {
    font-family: "Source Code Pro";
  }
  .faq-row,
  .faq-title {
    border: none !important;
  }
}

///////////////////// ON demond Section ////////////////////

.Ondemond-section {
  h2 {
    width: 100%;
    max-width: 47.5rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
    font-size: 3.5rem;
    line-height: 1.19;
    letter-spacing: 0.23875rem;
  }
  ul li,
  p {
    max-width: 32.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.67;
    font-family: "Source Code Pro";
    padding: 0px;
    margin-bottom: 20px;
  }
}

/////////////////////////////////////////////// devotee Section /////////////////////////////////

.Devotee-section {
  padding: 22.625rem 0 4.8125rem;
  margin: 1.875rem 0 7.5rem;
  background: url(../public/assets/bad-devotee-bg.png) no-repeat top;
  background-size: 88.125rem;

  h2 {
    width: 100%;
    max-width: 47.5rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
    font-size: 3.5rem;
    line-height: 1.19;
    letter-spacing: 0.23875rem;
  }
  p {
    max-width: 32.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.67;
    font-family: "Source Code Pro";
    padding: 0px;
    margin-bottom: 20px;
  }
}

///////////////////////////////// Footer section ///////////////

.footer-section {
  align-items: center;
  background: #c58fff;
  h3 {
    max-width: 38.75rem;
    margin-bottom: 0.625rem;
    font-size: 1.6rem;
    font-weight: 800;
    letter-spacing: 0.1625rem;
    line-height: 1.2;
    text-transform: uppercase;
  }
  h5 {
    margin-bottom: 1.875rem;
    font-weight: 600;
    font-size: 1.9rem;
    line-height: 1.25;
    letter-spacing: 0.01875rem;
    color: #fff;
  }
  .copyright {
    background: #aa75e3;
    font-family: "Source Code Pro";
  }
  form {
    input {
      border-radius: 0px;
      padding: 16px;
      font-family: "Source Code Pro";
      outline: none !important;
      transition:.4s ease all;
      box-shadow: none !important;
    }
    .btn-subscribe {
      font-family: "Source Code Pro";
      width: 11.25rem;
      height: 3.75rem;
      padding: 20px;
      background-color: #dcff02;
      font-size: 1.0625rem;
      font-weight: 500;
      text-align: center;
      color: #000;
      border-radius: 0;
      border: 1px solid #dcff02;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .social-icons {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        list-style: none;
        margin: 10px;
        a {
          background-color: #fff;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          padding: 20px;
          color: #aa75e3;
          font-size: 22px;
        }
      }
    }
  }

  .footer-menu {
    border-top: 2px solid #b785ee;
    ul {
      li {
        list-style: none;
        a {
          text-decoration: none;
          color: #fff;
          font-family: "Source Code Pro";
        }
      }
    }
  }
}
// //////////////////////////// Apply  /////////////////////////
.apply-section{
  h2,
  h4 {
    font-size: 3rem;
    margin-bottom: 3rem;
    font-weight: 700;
    text-align: center;
  }

    input {
      border-radius: 0px;
      padding: 16px;
      font-family: "Source Code Pro";
      outline: none !important;
      transition:.4s ease all;
      box-shadow: none !important;
      max-width: 550px;
    }
    textarea {
      border-radius: 0px;
      padding: 16px;
      font-family: "Source Code Pro";
      outline: none !important;
      transition:.4s ease all;
      box-shadow: none !important;
      max-width: 550px;
    }
    .btn-subscribe {
      font-family: "Source Code Pro";
      width: 11.25rem;
      height: 3.75rem;
      padding: 20px;
      background-color: #dcff02;
      font-size: 1.0625rem;
      font-weight: 500;
      text-align: center;
      color: #000;
      border-radius: 0;
      border: 1px solid #dcff02;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    
  }

}

/////////////////////////////// Media Query ////////////////////

@media only screen and (max-width: 600px) {
  h1,
  h2 {
    font-size: 2.5rem !important;
  }

  .Hero-section .content-sec .content-para,
  h4 {
    font-size: 1.8rem !important;
  }
  .custom-btn {
    padding: 1rem 2rem !important;
  }
  .testemonial-size {
    width: 38rem;
  }
  .Testemonial-section .testemonial-sec {
    padding: 0.875rem 1.3125rem 0.6875rem 1.25rem;
  }

  .Testemonial2-section .testemonial2-sec {
    width: 243px;
    padding: 0.875rem 0.3125rem 0.6875rem 0.25rem;
  }
  .Testemonial2-section .testemonial-icon {
    display: none !important;
  }
  .Testemonial4-section .testemonial1-content-sec .testemonial-sec {
    width: 19.0625rem;
    height: 16.3125rem;
    padding: 0.1rem 0.4375rem 0.4375rem 1.5rem;
    background-size: cover;
  }
  .testemonial-sec {
    margin: 0px !important;
  }

  .Testemonial4-section .testemonial2-content-sec .testemonial-sec {
    width: 19rem;
    height: 9.875rem;
    padding: 0.9375rem 0.0625rem 0.7375rem 0.8125rem;
    background-size: cover;
    margin: 0px !important;
  }
  .Testemonial4-section .testemonial2-content-sec .rotate-text {
    margin-left: 6.8125rem;
    bottom: 11.25rem;
  }
  .Testemonial3-section .testemonial1-content-sec .testemonial-sec {
    width: 18.0625rem;
    height: 25.3125rem;
    padding: 0.75rem 0.4375rem 0.4375rem 0.5rem;
    background-size: cover;
  }
  .Testemonial3-section .testemonial2-content-sec .testemonial-sec {
    width: 19rem;
    height: 13.875rem;
    padding: 0.9375rem 0.0625rem 0.375rem 0.8125rem;
    color: #ffffff;
  }
  .Testemonial3-section .testemonial1-content-sec .rotate-text {
    bottom: 25.75rem;
  }
  .Testemonial3-section .testemonial2-content-sec .rotate-text {
    bottom: -0.25rem;
    margin-left: 7.8125rem;
  }
  .Testemonial2-section .content-sec .rotate-text {
    left: -278px;
    bottom: 3.75rem;
    margin-left: 16.8125rem;
  }
  .Tower-section .tower-heading {
    line-break: anywhere;
  }
  .Testemonial-section .content-sec .rotate-text {
    bottom: -3.25rem;
    margin-left: 2.8125rem;
  }
  .resume-sec-part {
    margin-left: 0px;
  }
  .Testemonial2-section .content-sec,
  .testemonial1-content-sec,
  .testemonial2-content-sec,
  .Testemonial3-section .testemonial-size,
  .Testemonial3-section .testemonial1-content-sec {
    width: 17.875rem !important;
  }
  .Testemonial2-section .testemonial-size {
    width: 17.875rem !important;
  }
  .Utopia-section .skill {
    top: 3.1875rem;
    width: 7.9375rem;
  }
  .Utopia-section .right-hand {
    top: 7.0625rem;
    left: 8.1875rem;
    width: 8.9375rem;
  }
  .Utopia-section .left-hand {
    top: 6rem;
    left: 1.4375rem;
    width: 10.9375rem;
  }
  .Utopia-section .beard {
    width: 15.25rem;
  }
  .Utopia-section .beard-hand {
    top: 8.0625rem;
    left: 0.125rem;
    width: 4.875rem;
  }
  .Utopia-section .communication {
    top: 4.5rem;
    left: 7.875rem;
    width: 8.875rem;
  }
  .Utopia-section .dog-pic-combination .dog-hand-right {
    top: 18.0625rem;
    left: -.875rem;
    width: 11.875rem;
  }
  .Utopia-section .dog-pic-combination .dog-hand-left {
    left: 6.125rem;
    width: 11.875rem;
  }
  .Utopia-section .rex-pic-combination .communication {
    top: 2.5rem;
    left: 6.875rem;
    width: 8.875rem;
  }
  .Utopia-section .rex-pic-combination .rex-hand-right {
    top: 9.0625rem;
    left: -0.875rem;
    width: 5.375rem;
  }
  .Utopia-section .rex-pic-combination .rex-hand-left {
    top: 9.0625rem;
    left: 1.125rem;
    width: 5.875rem;
  }

  .header-section {
    ul li a {
      display: flex;
      justify-content: center;
    }
  }
  .Devotee-section h2 {
    font-weight: 600;
    // text-transform: uppercase;
    font-size: 1.5rem !important;
  }
}
